// Thank you! https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
function scrollFunction() {
    var header = document.getElementById("mainnav");
    if (document.body.scrollTop > 64 || document.documentElement.scrollTop > 64) {
        header.classList.add('scrolled')
        header.classList.remove('on-top')
    } else {
        header.classList.remove('scrolled')
        header.classList.add('on-top')
    }
}
window.onscroll = debounce(scrollFunction, 500, false)

if ('IntersectionObserver' in window) {
    let intersectionOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    }
    let intersectionCallback = (entries, observer) =>  {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible')
            } else {
                entry.target.classList.remove('visible')
            }
        });
    };
    let intersectionObserver = new IntersectionObserver(intersectionCallback, intersectionOptions);
    let postIts = document.querySelectorAll('.process ol li p');
    postIts.forEach(postit => {
        intersectionObserver.observe(postit);
    });
}

